import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { SEO } from "../components/common";
import Layout from "../components/common/Layout/Layout";
import App from "../components/App";
import { SiteInfo, Contact } from "../components/landing";

// import BannerAds from "../components/BannerAds"

const AllSitesPage = ({ pageContext }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const isSoundCloud = pageContext.node.name !== "Soundcloud";
  return (
    <>
      <SEO
        lang={language}
        title={
          pageContext.node.name === "Soundcloud"
            ? `${pageContext.node.name} ${t("template:Music Downloader")}`
            : `${pageContext.node.name} ${t("template:video Downloader")}`
        }
        description={
          isSoundCloud
            ? `${t("template:Download")} ${pageContext.node.name} ${t(
                "template:Video"
              )}, ${t("template:Save")} ${pageContext.node.name} ${t(
                "template:video online"
              )}, ${t("template:Simple copy and paste")} ${
                pageContext.node.name
              } ${t("template:video link and save")} ${
                pageContext.node.name
              } ${t("template:videos")}, ${pageContext.node.name} to mp4.`
            : `${t("template:Download")} ${pageContext.node.name} ${t(
                "template:Music"
              )}, ${t("template:Save")} ${pageContext.node.name} ${t(
                "template:Audio"
              )}, ${t("template:Simple copy and paste")} ${
                pageContext.node.name
              } ${t("template:link and save")} ${pageContext.node.name} ${t(
                "template:Music"
              )}.`
        }
        keywords={
          isSoundCloud
            ? [
                `${pageContext.node.name} ${t("template:video Downloader")}`,
                `${t("template:Download")} ${pageContext.node.name} ${t(
                  "template:Video"
                )}`,
                `${pageContext.node.name} to mp4`,
                `${t("template:Save")} ${pageContext.node.name} ${t(
                  "template:Video"
                )}`,
                `${t("template:online save")} ${pageContext.node.name} ${t(
                  "template:videos"
                )}`,
                `${pageContext.node.name} ${t("template:videos downloader")}`,
                `${t("template:download video")}`,
                `${t("template:save video")}`,
              ]
            : [
                `${pageContext.node.name} ${t("template:Music Downloader")}`,
                `${t("template:Download")} ${pageContext.node.name} ${t(
                  "template:Music"
                )}`,
                `${pageContext.node.name}`,
                `${t("template:Save")} ${pageContext.node.name} ${t(
                  "template:Audio"
                )}`,
                `${t("template:online save")} ${pageContext.node.name} ${t(
                  "template:Music"
                )}`,
                `${pageContext.node.name} ${t("template:Music Downloader")}`,
                `${t("template:download music")}`,
                `${pageContext.node.name} ${t("template:Music")}`,
              ]
        }
      />
  {/* <BannerAds /> */}
      <App
        titleContent={
          pageContext.node.name === "Soundcloud"
            ? `${pageContext.node.name}  ${t("template:Music Downloader")}`
            : `${pageContext.node.name}  ${t("home:title")}`
        }
      />
      <div id="container-3c14e353698e00f86bcd4b1ecabc0f41"></div>
      <SiteInfo isStatic={true} pageContext={pageContext} />
      <Contact />
    </>
  );
};

const customProps = {
  localeKey: "nodePages",
};

export default Layout(customProps)(AllSitesPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "home", "template"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
